import React, { lazy, Suspense, useEffect } from 'react'
import { ApolloProvider } from '@apollo/client'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Provider } from 'react-redux'
import { client } from './apolloClient'
import store from './store'
import { Indicators, theme, ThemeProvider } from 'ui'

const RootRouter = lazy(() => import('./routes'))

/**
 * The root component for this application. Sets up any appropriate
 * providers and enforces strict HTTPS.
 */
const App: React.FC = () => {
  useEffect(() => {
    // Force HTTPS
    if (
      window &&
      window.location &&
      window.location.protocol.indexOf('https') < 0 &&
      window.location.hostname !== 'localhost'
    ) {
      window.location.href = `https://${window.location.hostname}${window.location.pathname}`
    }
  })
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
          <ThemeProvider theme={theme}>
            <Suspense fallback={<Indicators.FullScreen />}>
              <RootRouter />
            </Suspense>
          </ThemeProvider>
        </DndProvider>
      </Provider>
    </ApolloProvider>
  )
}

export default App
