import React from 'react';
import { concatStyles } from 'utils';
var PAGE_STYLES = 'h-screen w-full text-body font-body flex flex-col';
var PREVIEW_STYLES = 'border print:border-0';
export var Page = function (_a) {
    var children = _a.children, preview = _a.preview;
    return (React.createElement("article", { className: concatStyles(preview && PREVIEW_STYLES, PAGE_STYLES), style: {
            padding: '1in',
            maxWidth: '8in',
            maxHeight: '10in',
        } }, children));
};
