import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import React from 'react'
import LogRocket from 'logrocket'
import LogrocketFuzzySanitizer from 'logrocket-fuzzy-search-sanitizer'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import ReactDOM from 'react-dom'
import App from './App'
import 'ui/lib/mcss-ui.scss'

import * as serviceWorker from './serviceWorker'
import ReactGA from 'react-ga'

const privateFieldNames = [
  'ssn',
  'firstName',
  'birthDate',
  'password',
  'Authorization',
]

const { requestSanitizer, responseSanitizer } =
  LogrocketFuzzySanitizer.setup(privateFieldNames)

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS ?? '')

LogRocket.init('rngp6b/mcss-homeschool-connect', {
  release: process.env.REACT_APP_ENVIRONMENT || 'local',
  network: {
    requestSanitizer: requestSanitizer as any,
    responseSanitizer: responseSanitizer as any,
  },
})

Sentry.init({
  dsn: 'https://1dfefa697a1341aab0d2d8c9dafecb06@o338085.ingest.sentry.io/5456305',
  environment: process.env.REACT_APP_ENVIRONMENT || 'local',
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
})

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
